<template>
    <div >
        <div class="tbar">
            <div class="titletxt">
 
                {{title}}
            </div>
            <div class="settings">
                <v-icon class="ma-0 pa-0" small  @click="dialog= !dialog" >mdi-cog</v-icon>
                <v-icon class="ma-0 pa-0" small  @click="dialog= !dialog" >mdi-help</v-icon>

            </div>
        </div>
        

        <div class="content pa-0" :style="`height: calc(${vh}vh - 32px);`">
            <slot></slot>
        </div>

        <v-dialog v-model="dialog"  max-width="70vw" >
            <v-sheet rounded="sm">
                <slot name="settings"></slot>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'app-sheet',
    components:{
    },
    emits:[
    ],
    data(){return {
        dialog:false,
        }
    },
    props: { 
        title:{type:String,default:''},
        vh:{type:Number,default:48},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">

.content{
    
    overflow-y:scroll;

    border-radius: 0 10px 10px 10px;
    backdrop-filter: blur(10px);
    max-width:100vw;
    background-color: #1e1e1ed0;
}

.tbar{
    width:100%;
    display:flex;
    justify-content: space-between;


    .titletxt{
        text-transform: uppercase;
        font-size: 15px!important;
        color: rgb(255 255 255 / 98%);
        letter-spacing: 1px!important;
        background-color:rgba(0, 0, 0, 0.307);
        border-radius: 5px 8px 0 0;
        padding:2px;
        padding-left:5px;
        backdrop-filter: blur(17px);

        
    }
    .settings{
        // background:rgb(187, 184, 184);
        //   background-color:rgba(0, 0, 0, 0.307);
        border-radius: 4px 4px 0 0;
    }
}

</style>