<template>
  <div>
    <app-sheet title="popular words">
      <div v-if="loading.stream">Loading...</div>
      <div v-else>
        <v-container fluid v-if="data">
          <v-row>
            <v-col cols="12" md="12" class="ma-0">
              <table v-if="data">
                <tr>
                  <th>word</th>
                  <th @click="sort_data('total_launched')">tokens</th>
                  <th @click="sort_data('total_trades')">Trades</th>
                  <th @click="sort_data('volume')">volume</th>
                  <!-- <th>
                    <div @click="sort_data('total_buyers')" > buys / sells  </div>
                    <div @click="sort_data('total_inflow')">in / out</div>
                  </th> -->
                </tr>

                  <tr v-if="m.word.includes(search)" v-for="(m, i) in data" :key="m.word">
                    <th @click="open_binned_dialog(m)"> {{ m.word.slice(0, 20) }} </th>
                    <td style="text-align: center">{{ m.total_launched }}</td>
                    <td style="text-align: center">{{ m.total_trades }}</td>
                    <td style="text-align: center">
                      <base-amount :amount="m.total_inflow + m.total_outflow">
                      </base-amount>
                    </td>
                    <!-- <td colspan="2">
                      <div @click="sort_data('total_buyers_ratio')" class="ratio-bar" >
                                                <div class="ratio-bar-first" :style="` width:${100*m.total_buyers / (m.total_buyers + m.total_sellers)}%;`"> {{ m.total_buyers }}</div>
                                                <div class="ratio-bar-last" :style="`width:${100*m.total_sellers / (m.total_buyers + m.total_sellers)}%;`"> {{ m.total_sellers }} </div>
                                            </div>
                      <div @click="sort_data('total_inflow_ratio')" class="ratio-bar" >
                        <div class="ratio-bar-first" :style="`width:${ (100 * m.total_inflow) / (m.total_inflow + m.total_outflow) }%;`" > {{ m.total_inflow.toFixed(1) }} </div>
                        <div class="ratio-bar-last" :style="`width:${ (100 * m.total_outflow) / (m.total_inflow + m.total_outflow) }%;`" > {{ m.total_outflow.toFixed(1) }} </div>
                      </div>
                    </td> -->
                  </tr>
              </table>
            </v-col>
            <v-col sm="12" md="12">
              <v-btn
                style="float: right"
                class="ma-0"
                elevation="2"
                small
                @click="do_get_data('true')"
                >download csv</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
        <div v-else style="display: flex; justify-content: center">
          <v-btn
            @click="binned_dialog = true"
            class="ma-1"
            elevation="2"
            color="white"
            light
            small
          >
            explore trends</v-btn
          >
        </div>
      </div>
      <template v-slot:settings>
        <div v-if="loading.stream">Loading...</div>
        <div v-else>
          <v-container fluid>
            <v-row>
              <v-col sm="12" md="12">
                <v-text-field
                  color="white"
                  label="Search Word"
                  v-model="search"
                  outlined
                  dense
                  rounded
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </v-col>
              <v-col sm="12" md="6">
                <v-select
                  rounded
                  solo
                  outlined
                  background-color="transparent"
                  justify-center
                  class="text-center"
                  v-model="pair_stream"
                  color="white"
                  :items="pair_streams"
                  label="In The Last"
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  rounded
                  solo
                  outlined
                  background-color="transparent"
                  justify-center
                  class="text-center"
                  v-model="timeframe"
                  color="white"
                  :items="time_intervals.list"
                  label="In The Last"
                  dense
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <span style="white-space: nowrap">
                  {{ data.length }}
                  <sup>Tkn</sup>
                </span>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </template>
    </app-sheet>

    <!-- {{start}} -->
    <!-- binned popup  -->
    <v-dialog v-model="binned_dialog" max-width="70vw">
      <v-card>
        <v-card-title>
          <span class="text-h5">Keyword Freq Analysis In Token Names</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="8">
                <intel-launch-word-binned-graph
                  :initialWord="selected_word"
                ></intel-launch-word-binned-graph>
              </v-col>
              <v-col cols="12" md="4">
                <v-tabs centered color="white">
                  <v-tab>ERC20s</v-tab>
                  <v-tab>Traders</v-tab>
                  <v-tab-item>
                    <table>
                      <tr v-for="p in selected_pairs" :key="p.id">
                        <td>
                          <pair-link-to-page :pair="p"></pair-link-to-page>
                        </td>
                        <td>
                          <base-amount :amount="p.inflow"> </base-amount>
                        </td>
                      </tr>
                    </table>
                  </v-tab-item>
                  <v-tab-item>
                    <!-- <v-btn class="ma-0"  elevation="2" small  block @click="do_calc_trader_scores(selected_traders)" >calc score all missing</v-btn> -->
                    <trader-id-list-actions
                      :traders="
                        selected_traders.map((x) => {
                          return { id: x };
                        })
                      "
                    ></trader-id-list-actions>

                    <table>
                      <tr v-for="t in selected_traders" :key="t">
                        <td>
                          <trader-link-to-page
                            :traderId="t"
                          ></trader-link-to-page>
                        </td>
                        <td v-if="selected_traders_scores[t]">
                          {{ selected_traders_scores[t].score_blsh.toFixed(1) }}
                        </td>
                      </tr>
                    </table>
                  </v-tab-item>
                </v-tabs>
                <!-- {{ selected_traders }} -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="white darken-1" text @click="binned_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState, mapActions } from "vuex";
import AppSheet from "@/components/app-sheet.vue";
import BaseAmount from "@/components/base-amount.vue";
import TraderIdListActions from "@/components/dynamic_lists/trader-id-list-actions.vue";
import IntelLaunchWordBinnedGraph from "@/components/intel-launch-word-binned-graph.vue";
import PairLinkToPage from "@/components/pair-link-to-page.vue";
import TraderLinkToPage from "@/components/trader-link-to-page.vue";
export default {
  name: "intel-launch-name-vocabulary",
  components: {
    IntelLaunchWordBinnedGraph,
    BaseAmount,
    PairLinkToPage,
    TraderLinkToPage,
    TraderIdListActions,
    AppSheet,
  },
  emits: [],
  data() {
    return {
      data: [],
      binned_dialog: false,
      selected_word: "inu",
      selected_traders: [],
      selected_traders_scores: {},
      selected_pairs: [],
      start: 0,
      end: 0,
      timeframe: "3H",
      pair_stream: "traded",
      pair_streams: ["launched", "traded"],
      loading: {
        stream: false,
      },
      search: "",
    };
  },
  props: {
    //propName:{type:String,default:''},
    //data:{type:Object,default:Object},
    //list:{type:Array,default:Array},
  },
  mounted() {
    this.end = Date.now() / 1000;
    this.start = this.end - 60 * 60 * 3;
    this.do_get_data(false);
  },
  methods: {
    ...mapActions(["intel_pairlaunch_name_wordusage", "traders_list_actions"]),
    do_get_data(use_csv) {
      if (use_csv == "true") {
        window.open(
          `${this.db_url}intel/pairs/launched/names/top?pair_stream=${this.pair_stream}cutoff_start=${this.start}&cutoff_end=${this.end}&csv=true`,
          "_blank"
        );
        return;
      }
      this.loading.stream = true;
      this.intel_pairlaunch_name_wordusage({
        pair_stream: this.pair_stream,
        cutoff_start: this.start,
        cutoff_end: this.end,
        csv: use_csv,
      }).then((r) => {
        this.loading.stream = false;
        this.data = r.data;
        this.data.sort((a, b) => b.total_buyers - a.total_buyers);
      });
    },
    sort_data(by_keyword) {
      if (by_keyword == "volume") {
        this.data.sort(
          (a, b) =>
            b.total_inflow +
            b.total_outflow -
            (a.total_inflow + a.total_outflow)
        );
        return;
      }
      if (by_keyword == "total_buyers_ratio") {
        this.data.sort(
          (a, b) =>
            b.total_buyers / (b.total_buyers + b.total_sellers) -
            a.total_buyers / (a.total_buyers + a.total_sellers)
        );
        return;
      }
      if (by_keyword == "total_inflow_ratio") {
        this.data.sort(
          (a, b) =>
            (100 * b.total_inflow) / (b.total_inflow + b.total_outflow) -
            (100 * a.total_inflow) / (a.total_inflow + a.total_outflow)
        );
        return;
      }
      this.data.sort((a, b) => b[by_keyword] - a[by_keyword]);
    },
    open_binned_dialog(word) {
      this.binned_dialog = !this.binned_dialog;
      this.selected_word = word.word;
      this.selected_pairs = word.pairs;
      this.selected_traders = word.traders;
    },
    do_get_trader_scores(trader_ids) {
      this.traders_list_actions({
        qs: { action: "list_get" },
        data: { trader_ids: trader_ids },
      }).then((r) => {
        this.selected_traders_scores = r.data;
      });
    },
  },
  computed: {
    ...mapState(["time_intervals", "db_url"]),
  },
  watch: {
    timeframe(newV, oldV) {
      this.end = Date.now() / 1000;
      this.start = this.end - this.time_intervals.seconds[newV];
      this.do_get_data();
    },
    selected_traders(nv, ov) {
      this.do_get_trader_scores(this.selected_traders);
    },
    pair_stream() {
      this.do_get_data(false);
    },
  },
};
</script>
<style scoped lang="scss">
.wordmention {
  text-transform: capitalize;
  text-align: center;
}
sup,
sub {
  color: gray;
}
tr:hover {
  background-color: rgba(255, 255, 255, 0.055);
}
.ratio-bar {
  width: calc(100% - 4px);
  margin: 2px;
  height: 17px;
  display: flex;
  .ratio-bar-first {
    background: linear-gradient(
      90deg,
      hsla(120, 60%, 43%, 0.386),
      hsla(120, 60%, 43%, 0.386),
      hsla(60, 60%, 43%, 0.386)
    );
    overflow: hidden;
    text-align: left;
    font-size: 0.7em;
    height: 100%;
  }
  .ratio-bar-last {
    overflow: hidden;
    background: linear-gradient(
      90deg,
      hsla(60, 60%, 43%, 0.386),
      hsla(0, 60%, 43%, 0.386),
      hsla(0, 60%, 43%, 0.386)
    );
    background-color: hsla(0, 60%, 43%, 0.486);
    text-align: right;
    font-size: 0.7em;
    height: 100%;
  }
  .ratio-bar-first:hover {
    background-color: hsla(120, 80%, 63%, 0.686);
    box-shadow: 0px 0px 10px hsla(120, 80%, 63%, 0.686);
  }
  .ratio-bar-last:hover {
    background-color: hsla(0, 80%, 63%, 0.686);
    box-shadow: 0px 0px 10px hsla(0, 80%, 63%, 0.686);
  }
}
</style>