<template>
    <div>

        <div id="cy"></div>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
// npm install cytoscape@3.22.1
import cytoscape from 'cytoscape';

export default {
    name: 'intel-trades-traders-agg-graph',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){

        this.intel_trade_pairs_agg().then(r=>{
            this.init_cy(r.data.cytoscape.nodes, r.data.cytoscape.edges)
        });

    
    },
    methods:{
        ...mapActions(['intel_trade_pairs_agg']),
        init_cy(nodes, edges){
                    const cy = cytoscape({ 
            elements: {nodes, edges}, 
            container: document.getElementById('cy'),
                    layout: {
                name: 'concentric', 
                nodeOverlap: 40, 
                padding: 30, 

                },
                style: [
                {
                    selector: 'node',
                    style: {
                    'background-color': 'white',
                    'width': 50, 
                    'height': 50, 
                    'label': 'data(label)'

                    }
                },
                {
                    selector: 'edge',
                    style: {
                    'width': 1,
                    'target-arrow-color': 'white',
                    'target-arrow-shape': 'triangle',
                    'curve-style': 'segments'
                    }
                }
                ],
                avoidOverlap: true // Enable avoidOverlap to prevent edge overlap

            });


        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
#cy{
    width:100%;
    height:400px;
    border: 1px solid rgba(231, 248, 235, 0.096);
}
</style>