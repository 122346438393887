<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <h2>Top Traders</h2>
                    <h5>by score</h5>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field @click="search_dialog=true" color="white"  label="Search Trader by Addrs or Id"  outlined dense rounded hide-details prepend-inner-icon="mdi-magnify"></v-text-field>
                </v-col>
                <v-col sm="12" md="12">
                    <v-select v-model="query.score" :items="['score_blsh','score_buyer','score_seller']" label="score" dense rounded solo hide-details background-color="transparent"  outlined >
                        <template v-slot:selection="{ item, index }">
                            {{item.replaceAll('_',' ')}}
                        </template>
                        <template v-slot:item="{ item, index }">
                            {{item.replaceAll('_',' ')}}
                        </template>
                    </v-select>
                </v-col>
                <v-col sm="12" md="12"  v-if="score_board">

                    <table>
                        <tr>
                            <th></th>
                            <th>trader</th>
                            <th>score</th>
                        </tr>
                        <tr v-for="(t,i) in score_board.top_traders" :key="t.id">
                            <td>{{ i + 1 }}.</td>
                            <td style="text-align: left;">
                                <trader-link-to-page :trader-id="t.id"></trader-link-to-page>
                            </td>
                            <td v-if="t[query.score]">{{ t[query.score].toFixed(1) }}</td>
                        </tr>
                        
                    </table>
                    <small style="float:right">

                    {{ (100* score_board.calculated_traders / score_board.total_traders).toFixed(2) }} % of
                    {{ score_board.total_traders.toLocaleString('en-US') }} Traders indexed
                    </small>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="search_dialog"  max-width="70vw" >
            <v-sheet rounded="sm">
                <trader-search></trader-search>
            </v-sheet>
        </v-dialog>
      
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import TraderLinkToPage from '../trader-link-to-page.vue'
import TraderSearch from './trader-search.vue'
export default {
    name: 'intel-traders-top',
    components:{
        TraderLinkToPage,
        TraderSearch
    },
    emits:[
    ],
    data(){return {
        search_dialog:false,
        query:{
            score:'score_blsh',
            max_n:30,
            min_last_seen:0,

        },
        score_board:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_trader_intel_top_traders()
    
    },
    methods:{
        ...mapActions(['trader_intel_top_traders']),
        do_trader_intel_top_traders(){

            this.trader_intel_top_traders({qs:this.query,data:{}}).then((r)=>{this.score_board = r.data})
        },
        epochToFormattedDate(epoch) {
          const date = new Date(epoch * 1000);
          // Extract day, month, hours, and minutes from the date object
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          return `${day}-${month} ${hours}:${minutes}`;
        },
        sort_by(metric){

        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        'query.score':function(nv,ov){
            this.do_trader_intel_top_traders()
        }
    },
}
</script>
<style scoped lang="scss">
a{
    color:white;
    text-decoration: none;
}
td{
    text-align: center;
}
</style>