<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <h3>Search For Trader</h3>
                    
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field color="white"  v-model="search"  label="Search Trader by Addrs or Id"  outlined dense rounded hide-details prepend-icon="mdi-magnify"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-btn class="ma-0"  elevation="2" small light block @click="do_trader_search" >search</v-btn>
                </v-col>
                <v-col sm="12" md="12">
                    <table v-if="results">
                        <tr>
                            <th> id </th>
                            <th> goto </th>
                            <th>buyer score </th>
                            <th>seller score </th>
                            <th>bhsl score </th>
                        </tr>
                        <tr v-for="t in results.results" :key="t.id">
                            <td> {{ t.id}} </td>
                            <td> <trader-link-to-page :traderId="t.id"></trader-link-to-page> </td>
                            <td>{{ t.score_buyer.toFixed(1) }}</td>
                            <td>{{ t.score_seller.toFixed(1) }}</td>
                            <td>{{ t.score_blsh.toFixed(1) }} x</td>
                        </tr>
                    </table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import TraderLinkToPage from '../trader-link-to-page.vue'
export default {
    name: 'trader-search',
    components:{
        TraderLinkToPage
    },
    emits:[
    ],
    data(){return {
        search:'',
        results:undefined,

        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['trader_search']),
        do_trader_search(){
            this.trader_search({qs:{search:this.search},data:{}}).then((r)=>{this.results = r.data})
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
td{
    text-align: center;
}
</style>