<template>
    <div>
        <v-sheet rounded="sm">
            <v-container fluid>
                <v-row>
                    <v-col cols="12" md="12">
                        <h2>Historical Gas Price</h2>
                    </v-col>
                    <v-col cols="12" md="12">
                        <canvas id="historicalPrice"></canvas>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import {Chart, registerables} from 'chart.js'
Chart.register(...registerables);

export default {
    name: 'gas-historical-chart',
    components:{
    },
    emits:[
    ],
    data(){return {
        cutoff_start:(Date.now()/1000) - (60*60*24*3),
        cutoff_end:Date.now()/1000,
        interval:3600,
        charts:{
            
        }

        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_gas_price_historical()
    },
    methods:{
        ...mapActions(['gas_price_historical']),
        do_gas_price_historical(){
            this.gas_price_historical({qs:{cutoff_start:this.cutoff_start, cutoff_end:this.cutoff_end, interval:this.interval},data:{}}).then((r)=>{
                this.data = r.data
                this.init_chart('historicalPrice', Object.keys(r.data.historical), [{label:'Gwei' , data:Object.values(r.data.historical), borderColor:'white'}])
            })
        },
        init_chart(chartname, labels, datasets){
            try{this.charts[chartname].destroy()}catch{}
            const ctx = document.getElementById(chartname);
            console.log("CONTEXT", ctx, datasets);
            this.charts[chartname] = new Chart(ctx, {
                type: 'line',
                data: { labels: labels, datasets: datasets, },
                options: {  }
            });
        },
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>