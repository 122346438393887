<template>
    <div>
        <v-container v-if="active_pairs" fluid>
            <v-row>
                <v-col sm="12" md="12">
                    <v-select rounded solo outlined background-color="transparent" justify-center class="text-center" v-model="timeframe" color="white" :items="time_intervals.list" label="In The Last" dense   hide-details ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <h5>top gainers ({{timeframe}})</h5>
                    <table>
                        <tr>
                            <th>name</th>
                            <th>dex</th>
                            <th>pct</th>
                        </tr>
                        <tr v-for="p in active_pairs.gainers" :key="p.id">
                            <td class="token-name"> 
                                ${{p.symbol}}
                                <small>{{p.name.slice(0,20)}}</small>
                            </td>
                            <td class=" dex-link"> 
                                <a  target="blank" :href="`https://www.dextools.io/app/en/ether/pair-explorer/${p.currency_address}`"> 
                                    <img height="20px" :src="`${db_url}static/img/dextools_logo.png`" alt="" srcset="">
                                </a>
                            </td>
                            <td class="pct-price positive"> ▲ {{p.price_change.pct.toFixed(1)}} %</td>
                        </tr>
                    </table>
                </v-col>
                <v-col cols="12" md="6">
                    <h5>top losers ({{timeframe}})</h5>
                    <table>
                        <tr>
                            <th>name</th>
                            <th>dex</th>
                            <th>pct</th>
                        </tr>
                        <tr v-for="p in active_pairs.losers" :key="p.id">
                            <td class="token-name"> 
                                ${{p.symbol}}
                                <small>{{p.name.slice(0,20)}}</small>
                            </td>
                            <td class=" dex-link"> 
                                <a  target="blank" :href="`https://www.dextools.io/app/en/ether/pair-explorer/${p.currency_address}`"> 
                                    <img height="20px" :src="`${db_url}static/img/dextools_logo.png`" alt="" srcset="">
                                </a>
                            </td>
                            <td class="pct-price negative"> ▼ {{p.price_change.pct.toFixed(1)}} %</td>
                        </tr>
                    </table>
                </v-col>

            </v-row>
        </v-container>
        <v-progress-circular v-else indeterminate color="white" ></v-progress-circular>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'intel-active-pairs-price',
    components:{
    },
    emits:[
    ],
    data(){return {
        active_pairs:undefined,
        timeframe:'1H',
        timedelta:60*60,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_active_pairs_pricewise()
    },
    methods:{
        ...mapActions(['get_active_pairs_pricewise']),
        do_get_active_pairs_pricewise(){
            this.active_pairs = undefined
            this.get_active_pairs_pricewise({timedelta:this.timedelta}).then(r=>{
                this.active_pairs = r.data
            })
        },
    },
    computed:{
        ...mapState(['db_url','time_intervals']),
    },
    watch:{
        timeframe(newV, oldV){
            this.timedelta = this.time_intervals.seconds[newV]
            this.do_get_active_pairs_pricewise()

        }
    },
}
</script>
<style scoped lang="scss">
.token-name{
        white-space: nowrap;
        small{
            color:gray;
        }
}
.pct-price{
    text-align: right;
    white-space: nowrap;
}
.positive{
    color:hsl(150, 62%, 42%);
}
.negative{
    color:hsl(330, 62%, 42%);
}
.dex-link{
    text-align: center;
}
</style>