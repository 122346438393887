<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col sm="12" md="5">
                    <h5>interval</h5>
                    <time-interval-selector @interval="(e)=>{query.interval = e}" :startTimeframe="'1D'" label="Interval"></time-interval-selector>
               
                    
                </v-col>
                <v-col sm="12" md="5">
                    <h5>period</h5>
                    <time-interval-selector   @interval="(e)=>{query.period = e}" :startTimeframe="'7D'" label="Period" ></time-interval-selector>
                    
                </v-col>
                <v-col sm="12" md="2">
                    <v-btn class="mt-5"  elevation="2"   block @click="do_defi_total_state_cashflow_graphs" >
                         <v-progress-circular indeterminate color="white" v-if="is_loading"></v-progress-circular>
                        <div v-else >get data</div>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="1">
                    
                    <v-btn class="mb-1"  block elevation="2" small :light="left_data == 'flow'"  @click="left_data = 'flow' ;init_chart('cashChart')" >flow</v-btn>
                    <v-btn class="mb-1"  block elevation="2" small :light="left_data == 'pairs'"  @click="left_data = 'pairs' ;init_chart('cashChart')" >pairs</v-btn>
                    <v-btn class="mb-1"  block elevation="2" small :light="left_data == 'traders'"  @click="left_data = 'traders' ;init_chart('cashChart')" >traders</v-btn>
                    <v-btn class="mb-1"  block elevation="2" small :light="left_data == 'volume'"  @click="left_data = 'volume' ;init_chart('cashChart')" >volume</v-btn>
                    <v-btn class="mb-1"  block elevation="2" small :light="left_data == 'buy'"  @click="left_data = 'buy' ;init_chart('cashChart')" >Bought</v-btn>
                    <v-btn class="mb-1"  block elevation="2" small :light="left_data == 'sell'"  @click="left_data = 'sell' ;init_chart('cashChart')" >Sold</v-btn>
                    
                </v-col>

                <v-col cols="12" md="10">
                    <canvas id="cashChart" ></canvas>
                    <!-- <pre v-if="data"> {{ data }} </pre> -->
                </v-col>
                <v-col sm="12" md="1">
                    <v-btn class="mb-1"  block elevation="2" small :color="right_data == 'usd_eth'? 'red':'default'"  @click="right_data = 'usd_eth' ;init_chart('cashChart')" >USD/ETH</v-btn>
                    <v-btn class="mb-1"  block elevation="2" small :color="right_data == 'usd_btc'? 'red':'default'"  @click="right_data = 'usd_btc' ;init_chart('cashChart')" >USD/BTC</v-btn>
                    <v-btn class="mb-1"  block elevation="2" small :color="right_data == 'btc_eth'? 'red':'default'"  @click="right_data = 'btc_eth' ;init_chart('cashChart')" >BTC/ETH</v-btn>

                    
                </v-col>
       
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import {Chart, registerables} from 'chart.js'
import TimeIntervalSelector from '../time-interval-selector.vue';

Chart.register(...registerables);

export default {
    name: 'intel-defi-cashflow-graph',
    components:{
        TimeIntervalSelector
    },
    emits:[
    ],
    data(){
        return {
        data:undefined,
        charts:{},
        left_data:'flow',
        right_data:'usd_eth',
        is_loading:false,
        query:{
            interval:60*60*24,
            period:60*60*24*7,
        }
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_defi_total_state_cashflow_graphs()
    
    },
    methods:{
        ...mapActions(['defi_total_state_cashflow_graphs']),
        do_defi_total_state_cashflow_graphs(){
            this.is_loading= true
            this.defi_total_state_cashflow_graphs({qs:this.query,data:{}}).then((r)=>{
                this.data = r.data;
                this.init_chart('cashChart')
                this.is_loading = false
            })
        },
        init_chart(chartName){

            try{this.charts[chartName].destroy()}catch{}
            const ctx = document.getElementById(chartName);




            this.charts[chartName] = new Chart(ctx, {
                type: 'line',
                data: {                    
                    datasets: [
                    {
                        label: this.right_data,
                        data: this.data[this.right_data],
                        borderColor: 'red',
                        pointBorderColor: 'rgba(255, 0, 0, 0.1)', // Point border color
                        pointRadius: 2, // Point radius
                        fill: false,
                        backgroundColor:'rgba(255,255,0,0.3)',
                        tension: 0.5,
                        borderWidth:2,
                        yAxisID: 'y2',
                    },
                    {
                        label: this.left_data,
                        data: this.data[this.left_data],
                        borderColor: '#d1ffff',
                        pointBorderColor: 'rgba(255, 255, 255, 0.1)', // Point border color
                        pointRadius: 5, // Point radius
                        fill: true,
                        backgroundColor:'rgba(255,255,255,0.3)',
                        tension: 0.5,
                        borderWidth:2,
                        yAxisID: 'y1',
                    },

                ]},
                options: {               
                    scales: {
                        x: {
                            beginAtZero: false,
                            type: 'linear',
                            title: { display: true, text: 'Time', },
                            ticks: { callback:  (value, index, values) =>{ return this.formatTimestamp(value); }, },
                            },

                        y1: {
                            id:'y1',
                            beginAtZero: false,
                            title: { display: true, text: this.left_data, },
                            position: 'left',
                            grid: {display:true , color:'rgba(255,355,255,0.1)'}

                        },
                        y2: {
                            id:'y2',
                            beginAtZero: true,
                            color:'red',
                            title: { display: true, text: this.right_data, color:'red' },
                            position: 'right',
                            grid: {display:true , color:'rgba(255,0,0,0.3)'}
                        }
                    }
                }
            }
                );


        },

        formatTimestamp(timestamp) {
                 
            
            const now = new Date();
            const date = new Date(timestamp*1000)
            const isToday = date.getDate() === now.getDate() &&
                            date.getMonth() === now.getMonth() &&
                            date.getFullYear() === now.getFullYear();

            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = String(date.getFullYear()).slice(-2);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            if (isToday) {
                return `${hours}:${minutes}`;
            } else {
                return `${day}-${month}-${year} ${hours}:${minutes}`;
            }
        },
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>