<template>
    <div>
        <app-sheet title="last hr data">
            <v-container  fluid>

                <v-row v-if="overall" >
                    <v-col cols="12" md="12">
                        <table>
                            <tr>
                                <td>
                                    <div class="ref-bar-label"> Trade Amts </div>
                                    <div class='middle-refrence-bar'> </div>
                                    <div class="ref-bar">

                                        <div :style="`text-align:center;background-color:rgb(203 106 109 / 95%);;width:${buy_sell_pct('sell', 'amt')*100}%`"> {{overall.direction_amt.sell.toFixed(1)}}</div>
                                        <div :style="`text-align:center;background-color:#28b645;width:${buy_sell_pct('buy', 'amt')*100}%`"> {{overall.direction_amt.buy.toFixed(1)}}</div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    
                                    <div class="ref-bar-label"> Trade Counts </div>
                                    <div class='middle-refrence-bar'> </div>
                                    <div class="ref-bar">

                                        <div :style="`text-align:center;background-color:rgb(203 106 109 / 95%);;width:${buy_sell_pct('sell', 'cnt')*100}%`"> {{overall.direction_cnt.sell}}</div>
                                        <div :style="`text-align:center;background-color:#28b645;width:${buy_sell_pct('buy', 'cnt')*100}%`"> {{overall.direction_cnt.buy}}</div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <table class="mt-1">
                            <tr>
                                <th>Tokens Traded</th>
                                <td>{{overall.tokens_traded}}</td>
                            </tr>
                            <tr>
                                <th>Tokens launched</th>
                                <td>{{overall.tokens_launched}}</td>
                            </tr>
                            <tr>
                                <th>Traders Active</th>
                                <td>{{overall.trader_cnt}}</td>
                            </tr>
                        </table>
                        <table class="mt-1">
                            <tr v-for="(prices , instrument) in overall.base_prices" :key="instrument">
                                <th>{{instrument}} </th>
                                <td>{{prices.price.toFixed(3)}}</td>
                            </tr>
                        </table>
                    <v-btn class="ma-1"  elevation="2" small @click="timeframe=3600;do_get_overall_activity_data" >
                        <v-progress-circular indeterminate color="white" small v-if="loading"></v-progress-circular>
                        <div v-else>
                            1h
                            <v-icon class="ma-0">mdi-refresh</v-icon>
                        </div>
                    </v-btn>
                    <v-btn class="ma-1"  elevation="2" small @click="timeframe=600; do_get_overall_activity_data()" >
                        <v-progress-circular indeterminate color="white" small v-if="loading"></v-progress-circular>
                        <div v-else>
                            10 min
                            <v-icon class="ma-0">mdi-refresh</v-icon>

                        </div>
                    </v-btn>
                    <v-btn class="ma-1"  elevation="2" small  @click="dialog_gas= !dialog_gas">

                            gas situation
                    </v-btn>
                    <!--  -->
                    </v-col>
        
                </v-row>
                <v-row v-else>
                    <v-col sm="12" md="12">
                        <v-btn class="ma-0"  elevation="2" small block :disabled="loading" @click="do_get_overall_activity_data" >
                            get data 
                            <v-progress-circular indeterminate :size="20" color="white" v-if="loading" ></v-progress-circular>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col sm="12" md="12">
                        <div style="text-align:center">
                            <span v-if="timeframe == 3600">1H</span>
                            <span v-if="timeframe == 600">10 min</span>
                            - Now
                        </div>
                        <div style="text-align:center">
                            <small v-if="overall">

                            {{overall.conclusion}}
                            </small>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </app-sheet>

        <v-dialog v-model="dialog_gas"  max-width="70vw" >
            <v-sheet rounded="sm" v-if="dialog_gas">
                <v-tabs centered color="white" >
                        <v-tab>Current Estimation</v-tab>
                        <v-tab>Scheduler</v-tab>
                        <v-tab>Historical</v-tab>
                        <v-tab-item>
                            <gas-schedule-estimator></gas-schedule-estimator>
                        </v-tab-item>
                        <v-tab-item>
                            <gas-latest-estimates></gas-latest-estimates>
                        </v-tab-item>
                        <v-tab-item>
                            <gas-historical-chart></gas-historical-chart>
                        </v-tab-item>
                </v-tabs>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import AppSheet from './app-sheet.vue'
import GasHistoricalChart from './gas-historical-chart.vue'
import GasLatestEstimates from './gas-latest-estimates.vue'
import GasScheduleEstimator from './gas-schedule-estimator.vue'
export default {
    name: 'intel-overal-trade-data',
    components:{
        GasLatestEstimates,
        GasScheduleEstimator,
        GasHistoricalChart,
        AppSheet
    },
    emits:[
    ],
    data(){return {
        overall:undefined,
        loading:false,
        timeframe:3600,
        dialog_gas:false,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    this.do_get_overall_activity_data()
    
    },
    methods:{
        ...mapActions(['overall_activity_data']),
        do_get_overall_activity_data(){
            this.loading  =  true
            this.overall_activity_data({qs:{timeframe:this.timeframe},data:{}}).then((r)=>{
                this.overall = r.data
                this.loading  =  false
            })
        },
        buy_sell_pct(side, cnt_or_amt){
            if(cnt_or_amt == 'cnt'){
                const cnt_total = this.overall.direction_cnt.sell +  this.overall.direction_cnt.buy
                if (side == 'buy'){ return this.overall.direction_cnt.buy / cnt_total}
                if (side == 'sell'){ return this.overall.direction_cnt.sell / cnt_total}
            }
            if(cnt_or_amt == 'amt'){
                const amt_total = this.overall.direction_amt.sell +  this.overall.direction_amt.buy
                if (side == 'buy'){ return this.overall.direction_amt.buy / amt_total}
                if (side == 'sell'){ return this.overall.direction_amt.sell / amt_total}
            }

        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.middle-refrence-bar{
    border-right: 1px solid white;
    height:5px;
    width:50%;
}
td{
    text-align: center;
}

.ref-bar{
     width:100%;
     display:flex;
    div:first-child{
        border-radius: 1em 0 0 1em;
    }
    div:last-child{
        border-radius: 0 1em 1em 0 ;
    }
}
    .ref-bar-label{
        text-transform: uppercase;
        font-size: 0.7em;
        padding-top: 0px;
        letter-spacing: 1px;
    }
</style>