<template>
    <div>
        {{ traders.length }} trader ids 
        <v-btn class="ma-0"  elevation="2" small light block @click="do_calc_trader_scores" >calculate scores</v-btn>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'trader-id-list-actions',
    components:{
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        traders:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions(['traders_list_actions']),
        do_calc_trader_scores(){
            this.traders_list_actions({qs:{action:'calc_missing_scores'},data:{'trader_ids':this.traders.map(x => x.id)}}).then((r)=>{
                
            })
        },

    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>