import { render, staticRenderFns } from "./general-metric-time-chart.vue?vue&type=template&id=87c49750&scoped=true&"
import script from "./general-metric-time-chart.vue?vue&type=script&lang=js&"
export * from "./general-metric-time-chart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87c49750",
  null
  
)

export default component.exports