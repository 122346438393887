import { render, staticRenderFns } from "./gas-historical-chart.vue?vue&type=template&id=8a647b22&scoped=true&"
import script from "./gas-historical-chart.vue?vue&type=script&lang=js&"
export * from "./gas-historical-chart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a647b22",
  null
  
)

export default component.exports