<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <h2>New pairs </h2>
                    <h5> on unsiwap v2</h5>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field color="white" @keydown.enter="do_load_latest_pairs_n(0)" label="Search Name symbol or Addrs" v-model="search.str" outlined dense rounded hide-details prepend-inner-icon="mdi-magnify"></v-text-field>
                </v-col>
                <v-col sm="12" md="12">
                    <v-select v-model="pair_stream" :items="['launched','bought','sold']" label="pair_stream" dense rounded solo hide-details background-color="transparent"  outlined >
                        <template v-slot:selection="{ item, index }">
                            {{item}}
                        </template>
                        <template v-slot:item="{ item, index }">
                            {{item}}
                        </template>
                    </v-select>
                    
                </v-col>
                <!-- <v-col sm="12" md="6">
                    
                </v-col> -->
                <v-col cols="12" md="12">
                    
                    <table v-if="pairs" class="new-pair-table">
                        <tr>
                            <th>ago</th>
                            <th>token</th>
                            <th>volume</th>
                            <th>traders</th>
                            <th>A</th>
                        </tr>
                        <tr v-for="p in pairs.pairs" :key="p.id">
                            <td>{{ formatTimeDelta(p.ago) }}</td>
                            <td>
                                <pair-link-to-page :pair="p"></pair-link-to-page>
                            </td>
                            <td>
                                <base-amount :amount="p.volume"></base-amount>
                                <!-- {{ p.volume}} -->
                            </td>
                            <td>
                                {{ p.trader_cnt }} <sub style="color:gray">x {{ p.trades_per_trader.toFixed(2) }}</sub>
                            </td>
                            <td>
                                <!-- {{ p.traders_x_volume / pairs.mx_traders_x_volume }} -->
                                <div :style="`width:15px; height:29px; background-color: rgba(255,255,0,${p.traders_x_volume / pairs.mx_traders_x_volume});`">

                                </div>
                            </td>
                        </tr>
                    </table>
                    <div class="loader-wrap" v-else >
                        <v-progress-circular  indeterminate color="white" ></v-progress-circular>
                    </div>
                </v-col>
                <v-col cols="6">
                    <v-btn class="ma-1" elevation="2" color="white" light small block to="/new-tokens">goto new tokens </v-btn>

                </v-col>

                <v-col cols="12" md="3">
                    <v-btn @click="goto_prev" v-if="offset" class="ma-1" elevation="2" color="default"  small block > <v-icon class="ma-0">mdi-chevron-left</v-icon> Prev </v-btn>
                </v-col>
                <v-col cols="12" md="3">
                    <v-btn @click="goto_next" class="ma-1" elevation="2" color="white" light  small block > Next <v-icon class="ma-0">mdi-chevron-right</v-icon> </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BaseAmount from './base-amount.vue'
import PairLinkToPage from './pair-link-to-page.vue'
export default {
    name: 'intel-latest-launched-pairs',
    components:{
        PairLinkToPage,
        BaseAmount
    },
    emits:[
    ],
    data(){return {
        offset:0,
        amount:14,
        pairs:undefined,
        pair_stream: 'launched',
        token_select:{},
        search:{
            str:'',
            date:undefined,
        }
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_load_latest_pairs_n(0)
    },
    methods:{
        ...mapActions(['load_latest_pairs_n']),
        do_load_latest_pairs_n(offset){
            this.load_latest_pairs_n({pair_stream:this.pair_stream, n:this.amount, offset:offset, search:this.search.str}).then(r=>{
                this.pairs = r.data
                console.log('PAIRS', r.data);
            })
        },
        goto_next(){
            this.offset = this.amount + this.offset
            this.do_load_latest_pairs_n(this.offset)
        },
        goto_prev(){
            this.offset =  this.offset - this.amount
            this.do_load_latest_pairs_n(this.offset)
        },
        formatTimeDelta(seconds) {
            if (seconds < 0) { return 'Invalid input'; }

            const days = Math.floor(seconds / (3600 * 24));
            const hours = Math.floor((seconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = Math.floor(seconds % 60);

            let result = '';

            if (days > 0) { result += `${days} day${days > 1 ? 's' : ''}, `; }

            result += `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;

            return result;
            }



    },
    computed:{
        ...mapState(['db_url']),
    },
    watch:{
        pair_stream(nv,ov){
            this.do_load_latest_pairs_n(0)
        }
    },
}
</script>
<style scoped lang="scss">
.centered{
    text-align: center;
}
small{
    color:gray;
}
a{
    text-decoration: none;
    color:white;
}

td{
    text-align:center;
}
</style>