import { render, staticRenderFns } from "./selector-pair-metric.vue?vue&type=template&id=0137ddbe&scoped=true&"
import script from "./selector-pair-metric.vue?vue&type=script&lang=js&"
export * from "./selector-pair-metric.vue?vue&type=script&lang=js&"
import style0 from "./selector-pair-metric.vue?vue&type=style&index=0&id=0137ddbe&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0137ddbe",
  null
  
)

export default component.exports