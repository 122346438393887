<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <time-interval-selector startTimeframe="1H"  @interval="(e)=>{interval = e}" ></time-interval-selector>
                </v-col>
                <v-col cols="12" md="12">
                    
                    <v-expansion-panels multiple v-if="data">
                        <v-expansion-panel v-for="(g,group_id) in data.groups" :key="group_id" >
                            <v-expansion-panel-header color="secondary" >
                                <sup> ({{g.cnt}} Tkns)  </sup>
                                {{g.name}}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="pair-chips-wrap">
                                    <div  v-for="(time_list, pid) in g.pairs" :key="pid" >
                                        <v-tooltip bottom color="white">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip 
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :color="pid == selected_pair.id? 'red': 'secondary'" 
                                                    @click="selected_pair.id = pid" 
                                                    small class="ma-1" 
                                                    :key="pid"> 
                                                        <span class="mr-1" style="color:gray"> ({{time_list.length}}x)  </span>
                                                        {{data.pairs[pid].symbol}}
                                                 
                                                        <external-link-dextools :tokenAddress="data.pairs[pid].currency_address" ></external-link-dextools>
                                                </v-chip>
                                            </template>
                                            <span style="color:black"><div v-for="t in time_list" :key="t"> {{epochToFormattedDate(t)}}</div></span>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-container>

  
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import ExternalLinkDextools from './external-link-dextools.vue'
import TimeIntervalSelector from './time-interval-selector.vue'
export default {
    name: 'intel-pairs-telegram-mentions-agg',
    components:{
        ExternalLinkDextools,
        TimeIntervalSelector,
    },
    emits:[
    ],
    data(){return {
        data:undefined,
        interval:3600,
        selected_pair:{id:0}
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_intel_telegram_get_pair_mentions()
    
    },
    methods:{
        ...mapActions(['intel_telegram_get_pair_mentions']),
        do_intel_telegram_get_pair_mentions(){
            const time_now = Date.now() / 1000
            const cutoffStart = time_now - this.interval
            this.intel_telegram_get_pair_mentions({ cutoff_start: cutoffStart, cutoff_end:time_now }).then((r)=>{
                this.data  = r.data
            })
        },
epochToFormattedDate(epoch) {
  const date = new Date(epoch * 1000); // Convert epoch to milliseconds

  // Extract day, month, hours, and minutes from the date object
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}-${month} ${hours}:${minutes}`;
}
    },
    computed:{
        ...mapState([]),
    },
    watch:{
        interval(oldV, newV){
            this.do_intel_telegram_get_pair_mentions()

        }
    },
}
</script>
<style scoped lang="scss">
.pair-chips-wrap{
    display:flex;
    flex-wrap: wrap;
}
</style>