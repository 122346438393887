<template>
    <div>
        <v-container fluid>
            <v-row>

                <v-col sm="12" md="6">
                    <v-menu v-model="cutoff_start_dialog" :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition" offset-y full-width max-width="290px" min-width="290px" >
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="front.cutoff_start" label="Start Date "  hide-details rounded outlined dense color="white" v-on="on" ></v-text-field> 
                        </template>
                        <v-date-picker color="gray" v-model="front.cutoff_start" no-title @input="cutoff_start_dialog = false"></v-date-picker>
                    </v-menu>                    
                </v-col>
                <v-col sm="12" md="6">
                    <v-menu v-model="cutoff_end_dialog" :close-on-content-click="false" :nudge-right="40" lazy transition="scale-transition" offset-y full-width max-width="290px" min-width="290px" >
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="front.cutoff_end" label="End Date ( Leave empty for current time )"  rounded outlined color="white" dense hide-details v-on="on" ></v-text-field> 
                        </template>
                        <v-date-picker color="gray" v-model="front.cutoff_end" no-title @input="cutoff_end_dialog = false"></v-date-picker>
                    </v-menu>                    
                </v-col>
                <v-col cols="12" md="6">
                    <v-select color="white" v-model="front.interval" :items="time_intervals.list" label="Interval" dense rounded outlined hide-details ></v-select>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field color="white" label="Key Words" rounded v-model="selected.word" outlined dense hint="For multiple words, seperate with spaces"></v-text-field>
                </v-col>
                <v-col sm="12" md="12">
                        <v-btn class="ma-1" rounded elevation="2" color="default" small block @click="do_intel_pairlaunch_binned_mentions"> Analyze </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <!-- {{selected}}
        {{front}}
         -->

        <canvas id="myChart"></canvas>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import {Chart, registerables} from 'chart.js'
Chart.register(...registerables);
export default {
    name: 'intel-launch-word-binned-graph',
    components:{
    },
    emits:[
    ],
    data(){return {
        interval_front:'1H',
        front:{
            cutoff_start:undefined,
            cutoff_end:undefined,
            interval:'1H',
        },
        cutoff_start_dialog:false,
        cutoff_end_dialog:false,
        selected:{
            word:'',
            cutoff_start:0,
            cutoff_end:0,
            interval:60*60,
        },
        data:undefined,
        chart:undefined,
        }
    },
    props: { 
        initialWord:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        
        this.selected.word = this.initialWord,
       
        this.front.cutoff_start = this.today(3)
        this.selected.cutoff_end =  Date.now()/1000
        // this.init_chart()
       
    },
    methods:{
        ...mapActions(['intel_pairlaunch_binned_mentions']),
        today(daysminus){
            var dateepoch = (new Date() / 1) - daysminus*1000*60*60*24
            const date = new Date(dateepoch)
            return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        },
        do_intel_pairlaunch_binned_mentions(){
            this.intel_pairlaunch_binned_mentions({...this.selected }).then((r)=>{
                this.data  = r.data.data
                this.init_chart(r.data.chart.labels, r.data.chart.datasets)
            })
        },
        init_chart(labels, datasets){
            try{this.chart.destroy()}catch{}
            const ctx = document.getElementById('myChart');
            this.chart = new Chart(ctx, {
                type: 'line',
                data: { labels: labels, datasets: datasets, },
                options: { scales: {  y: {  beginAtZero: true  }, x:{ }  } }
            });
        }
    },
    computed:{
        ...mapState(['time_intervals']),
    },
    watch:{
        initialWord(newV,oldV){
            this.selected.word = newV
        },
        'front.cutoff_start': function(newV,oldV){
            const d  = new Date(newV).getTime()
            this.selected.cutoff_start = d / 1000
        },
        'front.cutoff_end': function(newV,oldV){
            const d  = new Date(newV).getTime()
            
            this.selected.cutoff_end = d / 1000
        },
        'front.interval': function(newV,oldV){
            this.selected.interval = this.time_intervals.seconds[newV]
        },
        
    },
}
</script>
<style scoped lang="scss">
</style>