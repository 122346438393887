<template>
    <div>
        <v-sheet rounded="sm">
            <v-container fluid>
                <v-row>
                    <v-col sm="12" md="12">
                        <h2>Current Gas</h2>
                    </v-col>
                    <v-col cols="12" md="6" v-if="data">
                        <table>
                            <tr><th>Median</th><td><h3>{{ data.metrics.median.toFixed(0) }} Gwei</h3></td></tr>
                            <tr><th>std</th><td>  {{ data.metrics.std.toFixed(0) }} Gwei</td></tr>
                        </table>
                    </v-col>
                    <v-col cols="12" md="6" v-if="data">
                        
                        <table>
                            <tr><th>Pleb</th><td>{{ data.metrics.percentiles['5th'].toFixed(0) }} Gwei</td></tr>
                            <tr><th>Low</th><td>{{ data.metrics.percentiles['25th'].toFixed(0) }} Gwei</td></tr>
                            <tr><th>Market</th><td>{{ data.metrics.percentiles['50th'].toFixed(0) }} Gwei</td></tr>
                            <tr><th>Aggressive</th><td>{{ data.metrics.percentiles['90th'].toFixed(0) }} Gwei</td></tr>
                            <tr><th>Chad</th><td>{{ data.metrics.percentiles['95th'].toFixed(0) }} Gwei</td></tr>
                        </table>
                    </v-col>
                    <v-col cols="12" md="12">
                    </v-col>
                </v-row>
            </v-container>
            
            <canvas id="distribution"></canvas>
        </v-sheet>
        <!-- this is gas-latest-estimates
        <pre>
            {{data.metrics}}
        </pre> -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import {Chart, registerables} from 'chart.js'
Chart.register(...registerables);

export default {
    name: 'gas-latest-estimates',
    components:{
    },
    emits:[
    ],
    data(){return {
        charts:{
            
        },
        data:undefined,

        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_gas_estimation_histogram()
    },
    methods:{
        ...mapActions(['gas_estimation_histogram']),
        do_gas_estimation_histogram(){
            this.gas_estimation_histogram({qs:{cutoff_start:(new Date()/1000)-(60*30)},data:{}}).then((r)=>{
                this.data=r.data
                this.init_chart('distribution',r.data.chart.labels, [{label:'distribution gwei',data:r.data.chart.data, backgroundColor:'white'}])
            })
        },
        init_chart(chartname, labels, datasets){
            try{this.charts[chartname].destroy()}catch{}
            const ctx = document.getElementById(chartname);
            console.log("CONTEXT", ctx, datasets);
            this.charts[chartname] = new Chart(ctx, {
                type: 'bar',
                data: { labels: labels, datasets: datasets, },
                options: {  }
            });
        },
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>