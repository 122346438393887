<template>
    <div  class="trader-intel-cont"  >
        <app-sheet title="top traded tokens">
            <v-container fluid v-if="data">
                <v-row>
                    <v-col v-if="!is_loading" cols="12" md="12">
                        <table>
    
                            <tr>
                                <th></th>
                                <th>token</th>
                                <th>net</th>
                                <th>volume</th>
                                <th>tcount</th>
                                <th>trades</th>
                            </tr>
                            <tr v-for="(t,i) in data.trades" :key="t.token_id">
                                <td>{{i+1}}.</td>
                                <td>
                                    <pair-link-to-page :pair="data.tokens[t.token_id]"></pair-link-to-page>
                                </td>
                                <td> <base-amount :amount="t.net_amount"></base-amount> </td>
                                <td> <base-amount :amount="t.volume"></base-amount> </td>
                                <td> {{ t.trade_count }}</td>
                                <td>
                                    <v-btn class="ma-0" elevation="2" color="default" x-small   @click="set_selected_trades(t);trades_dialog= !trades_dialog" >
                                    <v-icon class="ma-0"  >mdi-swap-vertical</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </table>
                        <!-- <pre> {{data}}</pre> -->
                        <v-btn class="ma-0"  elevation="2" small  @click="max_disp_n = max_disp_n + 10" >more</v-btn>
                        <!-- trades popup  -->
                        <v-dialog v-model="trades_dialog"  max-width="70vw" >
                            <v-card>
                                        <v-card-title>
                                        <h2 class="text-h5 text--center">Trades</h2>
                                        </v-card-title>
                                        <v-card-text v-if="selected_trades">
                                            <table>
                                                <tr>
                                                    <th>id</th>
                                                    <th>time</th>
                                                    <th>direction</th>
                                                    <th>trader</th>
                                                    <th>amount</th>
                                                </tr>
                                                <tr v-for="t in selected_trades" :key="t.id">
                                                    <td>
                                                        {{ t.id }}
                                                    </td>
                                                    <td> 
                                                    {{temp_time_delta(t.time_epoch).toFixed(0) }} s ago
                                                    </td>
                                                    <td> {{t.direction}} </td>
                                                    <td>  <trader-link-to-page :trader-id="t.trader"></trader-link-to-page> </td>
                                                    <td> 
                                                        <base-amount :amount="t.amount"></base-amount>
                                                    </td>
                                                </tr>
                                            </table>
                                            <!-- {{selected_trades}} -->
                                            <trader-id-list-actions :traders="selected_trades.map(t=>{return {id:t.trader}})"></trader-id-list-actions>

                                        
                                        </v-card-text>
                                        <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="trades_dialog = false" > Close </v-btn>
                                        <v-btn color="blue darken-1" text @click="trades_dialog = false" > Save </v-btn>
                                        </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                    <v-col v-else sm="12" md="12">
                        Loading
                    </v-col>

                </v-row>
            </v-container>
            <template v-slot:settings>
                <v-sheet rounded="sm">
                    <v-container fluid>
                        <v-row>

                            <v-col cols="12" md="12">
                                <time-interval-selector start-timeframe="3H" @interval="(e) => interval = e" ></time-interval-selector>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-sheet>
            </template>
        </app-sheet>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import AppSheet from './app-sheet.vue'
import BaseAmount from './base-amount.vue'
import TraderIdListActions from './dynamic_lists/trader-id-list-actions.vue'
import ExternalLinkDextools from './external-link-dextools.vue'
import IntelTradesTradersAggGraph from './intel-trades-traders-agg-graph.vue'
import PairLinkToPage from './pair-link-to-page.vue'
import TimeIntervalSelector from './time-interval-selector.vue'
import TraderLinkToPage from './trader-link-to-page.vue'
export default {
    name: 'intel-trades-pairs-agg',
    components:{
        TimeIntervalSelector,
        ExternalLinkDextools,
        IntelTradesTradersAggGraph,
        PairLinkToPage,
        BaseAmount,
        TraderLinkToPage,
        TraderIdListActions,
        AppSheet
    },
    emits:[
    ],
    data(){return {
        data:undefined,
        trades_dialog:false,
        selected_trades:undefined,
        max_disp_n:25,
        interval:60*60*3,
        is_loading: false,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_intel_trade_pairs_agg()
        
    },
    methods:{
        ...mapActions(['intel_trade_pairs_agg']),
        do_intel_trade_pairs_agg(){
            this.is_loading = true
            this.intel_trade_pairs_agg({qs:{interval:this.interval, show_max:this.max_disp_n},data:{}}).then(r=> {this.data = r.data; this.is_loading=false;})
            
        },
        temp_time_delta(t){
            return (new Date()/1000) - t
        },
        set_selected_trades(t){
            this.selected_trades =[...t.buys.trades ,... t.sells.trades] 
            this.selected_trades = this.selected_trades.sort((a,b)=>{b.time_epoch - a.time_epoch})
            console.log(this.selected_trades.map(x=>x.time_stamp));
        }

    },
    computed:{
        ...mapState([]),
    },
    watch:{
        interval(nv,ov){this.do_intel_trade_pairs_agg()},
        max_disp_n(nv,ov){this.do_intel_trade_pairs_agg()},
    },
}
</script>
<style scoped lang="scss">

.trader-intel-cont{
    height: calc(50vh - 100px);
    overflow-y:scroll;
}
td{
    white-space: nowrap;
    text-align:center;
}

.bs-table{
    td, th {
        width:50%
    }
}

</style>