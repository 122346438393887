<template>
    <div >
        <v-app-bar color="#272727a0"  style="z-index:2">
            <v-tabs class="ma-0" centered color="white"  fixed-tabs  v-model="current_tab">
                    <v-tab> <v-icon class="mr-1">mdi-globe-model</v-icon>Explore</v-tab>
                    <v-tab><v-icon class="mr-1">mdi-swap-vertical</v-icon>trading</v-tab>
                    <v-tab ><v-icon class="mr-1"> mdi-chart-areaspline</v-icon> State of Market</v-tab>
            </v-tabs>
        </v-app-bar>
        <div style="margin-top:-50px; z-index:-1" >
            <v-tabs class="ma-0" centered color="white"   v-model="current_tab">
                    <!-- explore  -->
                    <v-tab-item >
                        <v-container fluid class="first-tab">
                            <v-row>
                                <v-col cols="12" md="4" class="mb-0 pb-0">
                                        <intel-launch-name-vocabulary></intel-launch-name-vocabulary>
                                </v-col>
                                
                                <v-col cols="12" md="4" class="mb-0 pb-0">
                                    <intel-overal-trade-data></intel-overal-trade-data>
                                </v-col>

                                <v-col cols="12" md="4" class="mb-0 pb-0">
                                        <intel-blockchain-activity-addrs-cnt></intel-blockchain-activity-addrs-cnt>
                                </v-col>

                                <v-col sm="12" md="12" class="mt-0 mt-0">
                                        <intel-trades-pairs-agg></intel-trades-pairs-agg>
                                </v-col>
                                

                                <!-- <v-col cols="12" md="4">
                                    <v-sheet rounded="sm"  class="top-row">
                                        <intel-latest-launched-pairs></intel-latest-launched-pairs>
                                        <general-metric-time-chart></general-metric-time-chart>
                                    </v-sheet>
                                </v-col> -->

                            </v-row>
                        </v-container>
                    </v-tab-item>



                    <!-- trading -->
                    <v-tab-item>
                        <v-container fluid>
                            <v-row>
                                <v-col sm="12" md="6">
                                    <v-sheet rounded="sm">
                                        <h3> Trader Trades</h3>
                                        <intel-trades-traders-agg></intel-trades-traders-agg>
                                    </v-sheet>
                                </v-col>

                                <v-col sm="12" md="6">
                                    <v-sheet rounded="sm" style="max-height:92vh; overflow:scroll" >
                                        <h3>Live Trades</h3>
                                        <trades-live-streamer></trades-live-streamer>
                                        <!-- <intel-traders-top></intel-traders-top> -->
                                    </v-sheet>
                                </v-col>
    
                            </v-row>
                        </v-container>
                        
                    </v-tab-item>

                    <!-- state of the market -->
                    <v-tab-item>
                        <v-sheet rounded="sm">
                            <intel-state-of-market></intel-state-of-market>
                        </v-sheet>
                    </v-tab-item>

                    <!-- social  -->
                    <v-tab-item>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4">
                                    <v-sheet rounded="sm">
                                        <h3> tg calls </h3>
                                            <intel-pairs-telegram-mentions-agg></intel-pairs-telegram-mentions-agg>
                                        <center style="color:gray">

                                        </center>
                                        <div id="output"></div>
                                    </v-sheet>
                                    <test-streaming></test-streaming>
                                </v-col>
                            </v-row>
                        </v-container>

                    </v-tab-item>

                    
                    <v-tab-item>
                        <v-container>
                            <v-row>

                            </v-row>
                        </v-container>

                    </v-tab-item>
            </v-tabs>
        </div>

    </div>
</template>
<script>

// Begin Component Imports
import IntelActivePairsPrice from './intel-active-pairs-price.vue'
import IntelLatestLaunchedPairs from './intel-latest-launched-pairs.vue'
import IntelLaunchNameVocabulary from './intel-launch-name-vocabulary.vue'
import IntelPairsTelegramMentionsAgg from './intel-pairs-telegram-mentions-agg.vue'
import IntelTradesPairsAgg from './intel-trades-pairs-agg.vue'
import IntelTradesTradersAgg from './intel-trades-traders-agg.vue'
import TestStreaming from './test-streaming.vue'
import GeneralMetricTimeChart from './intel/general-metric-time-chart.vue'
import IntelTradersTop from './intel/intel-traders-top.vue'
import IntelStateOfMarket from './intel/intel-state-of-market.vue'
import IntelBlockchainActivityAddrsCnt from './intel-blockchain-activity-addrs-cnt.vue'
import IntelOveralTradeData from './intel-overal-trade-data.vue'
// End Component Imports
import { mapState,mapActions } from 'vuex'
import tradesLiveStreamer from './trades-live-streamer.vue'

export default {
    name: 'intel-view',
    components:{
        IntelActivePairsPrice,
        IntelLatestLaunchedPairs,
        IntelLaunchNameVocabulary,
        IntelPairsTelegramMentionsAgg,
        TestStreaming,
        IntelTradesTradersAgg,
        IntelTradesPairsAgg,
        GeneralMetricTimeChart,
        IntelTradersTop,
        IntelStateOfMarket,
        IntelBlockchainActivityAddrsCnt,
        IntelOveralTradeData, 
        tradesLiveStreamer,
    },
    emits:[
 ,
          ],
    data(){return {
        current_tab:0,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),

    },
    computed:{
        ...mapState(['db_url']),
    },
    watch:{
    },
}
</script>
<style  lang="scss">

.theme--dark.v-tabs-items {
    background-color: #a7161600;
}


h3{
    padding-top:0.5em;
}
.top-row{
    height: calc(50vh - 50px);
    overflow-y:scroll;
}
.bottom-row{
    height: calc(50vh - 50px);
    overflow-y:scroll;
}
.dull-row{
    height: calc(100vh - 100px);
    overflow-y:scroll;
    
}





</style>