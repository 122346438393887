<template>
    <div>
        <app-sheet title="BC activity">

            <v-container fluid v-if="activity" >
                <v-row>


                    <v-col sm="12" md="12">
                        <table>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>wallet</th>
                                <th>pct</th>
                                <th>tx / min</th>
                            </tr>
                            <tr v-for="(wallet,i) in activity.counts" :key="i">
                                <td style="text-align:center" >{{i+1}}.</td>
                                <td> <external-link-etherscan :hash="wallet.wallet" type="address"></external-link-etherscan> </td>
                                <td class="wallet-col"> 
                                    <div v-if="wallet.wallet_name  != '?'  "> {{wallet.wallet_name}} </div>
                                    <div style="color:gray;" v-else> {{wallet.wallet.slice(0,5)}}___{{wallet.wallet.slice(37,40)}} </div>
                                </td>
                                <td>
                                    {{wallet.pct.toFixed(1)}}%
                                </td>
                                <td>
                                    {{((wallet.cnt/ (activity.end - activity.start))*60).toFixed(1)}} 
                                </td>

                            </tr>

                        </table>
                        
                    </v-col>
                    <v-col cols="12" md="12">
                        <div style="display:flex;justify-content:center">
                            <time-display :time="activity.start"></time-display> - 
                            <time-display :time="activity.end"></time-display> 
                        </div>
                        <div style="display:flex;justify-content:center">

                            <small>
                                ({{activity.block_cnt}} blocks , {{(activity.total_txs / activity.block_cnt).toFixed(0)}} txs per block)
                            </small>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </app-sheet>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import AppSheet from '@/components/app-sheet.vue'
import ExternalLinkEtherscan from '@/components/external-link-etherscan.vue'
import TimeDisplay from '@/components/time-display.vue'
export default {
    name: 'intel-blockchain-activity-addrs-cnt',
    components:{
        ExternalLinkEtherscan,
        TimeDisplay,
        AppSheet
    },
    emits:[
 
           ],
    data(){return {
        activity :undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_latest_blockchain_addrs_count_activity()
    
    },
    methods:{
        ...mapActions(['get_latest_blockchain_addrs_count_activity']),
        do_get_latest_blockchain_addrs_count_activity(){
            this.get_latest_blockchain_addrs_count_activity({qs:{},data:{}}).then((r)=>{
                this.activity = r.data
            })
        }
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
th{
    white-space: nowrap;
}
td{
    text-align:center;
}
.wallet-col{
    text-align: right;
    padding-right:3px;
}
</style>