<template>
    <div>
        <v-sheet rounded="sm" >
            <!-- {{weekyear_min_max}} -->
            <div v-if="loading">
                 <v-progress-circular indeterminate color="white" ></v-progress-circular>
            </div>
            <div v-else>
                <div v-if="data">

                    <v-btn class="ma-1"  elevation="2" small  @click="selected_metric = 'avg'" >Avg Gas Price</v-btn>
                    <v-btn class="ma-1"  elevation="2" small  @click="selected_metric = 'std'" >Standard Deviation</v-btn>
                    <v-btn class="ma-1"  elevation="2" small  @click="selected_metric = 'cnt'" >Trades</v-btn>
                    <table>
                        <tbody>
                            <tr >
                                <th>week</th>
                                <th v-for="i in 7" :key="i"> {{weekdays[i-1]}} </th>
                            </tr>
                            <tr v-for="(week_data,week_year) in data" :key="week_year" @click="selected_week_year = week_year" >
                                <th>wk {{week_data.week_nr}} of {{week_data.year}}</th> 
                                <td v-for="i in 7" :key="i" >  
                                    <div v-if="week_data.days[`${i-1}`]['totals'][selected_metric]" :style="`background-color:rgba(255,0,0,${ (week_data.days[`${i-1}`]['totals'][selected_metric] - weekyear_min_max.min)/ weekyear_min_max.df})`">
                                        {{ week_data.days[`${i-1}`]['totals'][selected_metric].toFixed(3)}} 
                                    </div>
                                    <div v-else>
                                        -
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                    <div  v-if="selected_week_year">
                        <h3> {{selected_week_year.replaceAll('_',' ')}}</h3>
                        <table>
                            <tr>
                                <td> </td>
                                <th v-for="hrnr in 24"  :key="hrnr"> {{hrnr - 1}}</th>
                            </tr>
                            <tr v-for="(daydata, daynr) in data[selected_week_year]['days']" :key="daynr">
                                <th>{{weekdays[daynr]}}</th>
                                <td v-for="hrnr in 24"  :key="hrnr"> 
                                    <div v-if="data[selected_week_year]['days'][daynr]['hours'][hrnr-1]">
                                        <div 
                                        v-if="data[selected_week_year]['days'][daynr]['hours'][hrnr-1].data[selected_metric]"
                                        :style="`background-color:rgba(255,255,0,${ (data[selected_week_year]['days'][daynr]['hours'][hrnr-1].data[selected_metric] - week_min_max.min)/ week_min_max.df})`"
                                        >
                                            {{data[selected_week_year]['days'][daynr]['hours'][hrnr-1].data[selected_metric].toFixed(0)}}
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        </table>

                    </div>
                </div>
            
            </div>
            <!-- <pre>{{data}}</pre> -->

        </v-sheet>
        <div v-if="false">

            <v-sheet rounded="sm"  >
                <v-container fluid >
                    <v-row  >
                        <v-col sm="12" md="12">
                            <h2>gas Schedule and cycles</h2>
                            
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <canvas id="weekdayChart"></canvas>
                            <v-btn class="ma-1"  elevation="2" small  @click="pval_dialog_weekday = true" >accuracy</v-btn>
                        </v-col>
                        <v-col cols="12" md="6">
                            <canvas ref="hoursChart" id="hoursChart"></canvas>
                            <v-btn class="ma-0"  elevation="2" small  @click="pval_dialog_hours = true" >accuracy</v-btn>
                        </v-col>
                        
                    </v-row>
                </v-container>
                
            </v-sheet>
            
            <v-dialog v-model="pval_dialog_hours"  max-width="70vw" >
                <v-sheet rounded="sm">
                    <div  v-if="data">
                        <h4>Hours</h4>
                        <div style="text-align: center; color:gray; font-size:0.7em">How much does the hour[row] look like it fits into hour[col]?</div>
                        <table>
                            <tr>
                                <th></th>
                                <th v-for=" h1 in times" :key="`h-${h1}`" > {{ h1 }}</th>
                            </tr>
                            <tr v-for="h1 in times" :key="`r-${h1}`">
                                <th>{{ h1 }}</th>
                                <td  v-for=" h2 in times" :key="`c-${h1}-${h2}`" :style=" `background-color: rgba(255,0,0,${data.pvals.hours[h1][h2]});`"> </td>
                            </tr>
                        </table>

                    </div>
                    
                </v-sheet>
            </v-dialog>
            <v-dialog v-model="pval_dialog_weekday"  max-width="70vw" >
                <v-sheet rounded="sm">
                    <div  v-if="data">
                        <h4>week day</h4>
                        <div style="text-align: center; color:gray; font-size:0.7em">How much does the day[row] look like it fits into day[col]?</div>
                        <table>
                            <tr>
                                <th></th>
                                <th v-for=" h1 in weekdays" :key="`h-${h1}`" > {{ h1 }}</th>
                            </tr>
                            <tr v-for="h1 in weekdays" :key="`r-${h1}`">
                                <th>{{ h1 }}</th>
                                <td  v-for=" h2 in weekdays" :key="`c-${h1}-${h2}`" :style=" `background-color: rgba(255,0,0,${data.pvals.weekday[h1][h2]});`"> </td>
                            </tr>
                        </table>
                    </div>
                </v-sheet>
            </v-dialog>
        </div>

    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import {Chart, registerables} from 'chart.js'
Chart.register(...registerables);
export default {
    name: 'gas-schedule-estimator',
    components:{
    },
    emits:[
    ],
    data(){return {
        data:undefined,
        selected_metric:'avg',
        selected_week_year:undefined,
        loading:false,
        weekdays:['mon','tue','wed','thu','fri','sat','sun'],




        weekdays:['Mon','Tue','Wed','Thurs','Fri','Sat','Sun'],
        times :Array.from({ length: 24 }, (_, index) => index).map(num => `${num}:00`),
        charts:{
            'hoursChart':undefined,
            'weekdayChart':undefined,
        },
        pval_dialog_hours:false,
        pval_dialog_weekday:false,

        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.new_do_gas_estimation_schedule()
        // this.do_gas_estimation_schedule()

    },
    
    methods:{
        ...mapActions(['gas_estimation_schedule']),
        do_gas_estimation_schedule(){
            this.gas_estimation_schedule({qs:{time_delta:6*7*24*60*60},data:{}}).then((r)=>{
                this.data= r.data
                this.init_chart('hoursChart', Object.keys(r.data.hour_prices), [{label:'Hours Gwei' , data:Object.values(r.data.hour_prices), backgroundColor:r.data.currentHourColors}])
                this.init_chart('weekdayChart', this.weekdays, [{label:'Weekday Gwei' , data:Object.values(r.data.weekday_prices), backgroundColor:r.data.currentDayColors}])
            
            })
        },
        init_chart(chartname, labels, datasets){
            try{this.charts[chartname].destroy()}catch{}
            const ctx = document.getElementById(chartname);
            // const ctx = this.$refs.hoursChart
            console.log("CONTEXT", ctx, datasets);
            this.charts[chartname] = new Chart(ctx, {
                type: 'bar',
                data: { labels: labels, datasets: datasets, },
                options: {  }
            });
        },
        new_do_gas_estimation_schedule(){
            this.loading = true
            this.gas_estimation_schedule({qs:{time_delta:6*7*24*60*60},data:{}}).then((r)=>{
                this.data= r.data
                this.loading = false

            
            })
        },
    },
    computed:{
        ...mapState([]),
        weekyear_min_max(){
            if (!this.data){return {min:0,max:0,df:1}}
            if (!this.selected_metric){return {min:0,max:0,df:1}}
            let mn = 10e10
            let mx = 0
            for (const week_year in this.data) {
                if (Object.hasOwnProperty.call(this.data, week_year)) {
                    const wkyear = this.data[week_year];
                    
                    for (const daynr in wkyear.days) {
                        if (Object.hasOwnProperty.call(wkyear.days, daynr)) {
                            const daydata = wkyear.days[daynr];
                            if(daydata.totals[this.selected_metric]){
                                if(daydata.totals[this.selected_metric] > mx){ mx = daydata.totals[this.selected_metric]}
                                if(daydata.totals[this.selected_metric] < mn){ mn = daydata.totals[this.selected_metric]}
                            }
                            
                        }
                    }
                    
                }
            }
            return {min:mn, max:mx,df:mx-mn}

        },
        week_min_max(){
            if (!this.data){return {min:0,max:0,df:1}}
            if (!this.selected_metric){return {min:0,max:0,df:1}}
            if (!this.selected_week_year){return {min:0,max:0,df:1}}
            let mn = 10e10
            let mx = 0
  
                    
            for (const daynr in this.data[this.selected_week_year].days) {
                const wkyear = this.data[this.selected_week_year];
                if (Object.hasOwnProperty.call(wkyear.days, daynr)) {
                    const daydata = wkyear.days[daynr];
                    for (const hrnr in daydata.hours) {
                        if (Object.hasOwnProperty.call(daydata.hours, hrnr)) {
                            const hourdata = daydata.hours[hrnr];
                            
                            if(hourdata.data[this.selected_metric]){
                                if(hourdata.data[this.selected_metric] > mx){ mx = hourdata.data[this.selected_metric]}
                                if(hourdata.data[this.selected_metric] < mn){ mn = hourdata.data[this.selected_metric]}
                            }
                            
                        }
                    }
                    
                }
            }
                    
                
            
            return {min:mn, max:mx,df:mx-mn}

        }
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
th{
    font-size: 0.8em;
}
</style>