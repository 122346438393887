<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <selector-metric @selected="(m) => {selected_metric = m}"></selector-metric>
                    
                </v-col>
                <v-col cols="12" md="12">
                    <v-container fluid v-if="selected_metric">
                        <v-row>
                            <v-col sm="12" md="12">
                                <h3>Condition specification</h3>
                                
                            </v-col>
                        </v-row>
                            <v-row  v-for="arg in selected_metric.args_spec" :key="arg.name" >
                             
                                <v-col sm="12" md="5">
                                    {{arg.name}}
                                </v-col>
                
                                <v-col cols="12" md="6">
                                    <v-select 
                                        v-if="arg.type == 'bool' " 
                                        v-model="arg.value" :items="[true, false]" 
                                        label="arg.val" 
                                        dense rounded solo hide-details >
                                    </v-select>
                                    <v-text-field v-else
                                    :type="decide_type(arg.type)" 
                                    :placeholder="arg.example"
                                    :label="arg.name" v-model="arg.value" outlined dense
                                
                                    >
                                    </v-text-field>
                                </v-col>
        
                            </v-row>
                            <v-row>
                                <v-container fluid>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-select v-model="query.stream" :items="['created','traded']" label="query.stream" dense rounded solo hide-details >
                                                
                                            </v-select>
                                            
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select v-model="query.aggregate_function" :items="['average','median','standard_dev']" label="query.stream" dense rounded solo hide-details >
                                                
                                            </v-select>

                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <time-interval-selector @interval="(e)=>selected_interval = e"></time-interval-selector>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <time-interval-selector @interval="(e)=>selected_interval_start_time = e"></time-interval-selector>
                                        </v-col>
                                        <v-col sm="12" md="12">
                                            <v-btn class="ma-0"  elevation="2" small light block @click="do_metric_latest_data_binned_time" >get data</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-row>
                        </v-container>
                        <div v-if="time_data">
                            <table>
                                <tr v-for="(_,dataset_name) in time_data.vertical_data.average" :key="dataset_name">
                                    <th> {{ dataset_name }}</th>
                                    <td>
                                        <v-btn class="mr-1"  elevation="2" small light  @click="init_chart_ds('average',dataset_name)" >average</v-btn>
                                        <v-btn class="mr-1"  elevation="2" small light  @click="init_chart_ds('median',dataset_name)" >median</v-btn>
                                        <v-btn class="mr-1"  elevation="2" small light  @click="init_chart_ds('standard_deviation',dataset_name)" >standard_deviation</v-btn>

                                    </td>
                                </tr>
                            </table>
                        </div>
                        <canvas id="timechart"></canvas>

                    <!-- <pre>
                        {{ query }}
                        {{ selected_metric }}
                    </pre> -->
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import SelectorMetric from '../selector-pair-metric.vue'
import TimeIntervalSelector from '../time-interval-selector.vue'
import {Chart, registerables} from 'chart.js'
import 'chartjs-plugin-error-bars';
import { BarWithErrorBarsController, BarWithErrorBar, LineWithErrorBar} from 'chartjs-plugin-error-bars';

Chart.register(...registerables);

export default {
    name: 'general-metric-time-chart',
    components:{
        SelectorMetric,
        TimeIntervalSelector
    },
    emits:[
    ],
    data(){return {
        selected_metric:undefined,
        selected_interval:600,
        selected_interval_start_time:undefined,
        charts:{},
        chart_data:{
    labels: [],
    datasets: [ ],
},
chart_options:{},
        time_data:undefined,
        query:{
            stream:'created',
            metric_name:undefined,
            time_start:0,
            time_end:0,
            interval:600,
            aggregate_function:'average',
            args_spec:{},
        }
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.query.time_end = new Date() / 1000
        this.query.time_start = this.query.time_end - 600
        // this.init_chart('timechart')
    
    },
    methods:{
        ...mapActions(['metric_latest_data_binned_time']),
        decide_type(typen){
            if (typen ==  undefined){ return 'text'}
            if (typen == 'int'){ return 'number'}
            if (typen == '_empty'){ return 'text'}
            return 'text'
        },
        do_metric_latest_data_binned_time(){
            this.query.name = this.selected_metric.name
            this.query.interval = this.selected_interval
            this.query.time_end = new Date() / 1000
            this.query.time_start = this.query.time_end - this.selected_interval_start_time
            this.query.args_spec = this.selected_metric.args_spec

            this.metric_latest_data_binned_time({qs:{},data:this.query}).then(r=>{
                this.time_data = r.data
            })
        },
        init_chart(chartname){
            try{this.charts[chartname].destroy()}catch{}
            const ctx = document.getElementById(chartname);
            this.charts[chartname] = new Chart(
                ctx, 
                {
                    type: 'line',
                    data:this.chart_data,
                    options:this.chart_options,
                }
            );
        },
        init_chart_ds(agg_function, dataset_name){
            this.chart_data = {
                labels: this.time_data.vertical_data.labels,
                datasets: [
                    {
                        label: `${dataset_name}[ ${agg_function}]`,
                        data: this.time_data.vertical_data[agg_function][dataset_name],
                        backgroundColor: 'white',
                        borderColor: 'white',
                        yAxisID:'left_y_axis'
                    },
                    {
                        label: 'pairs',
                        data: this.time_data.vertical_data.counts,
                        backgroundColor: 'gray',
                        borderColor: '#00000050',
                        yAxisID:'right_y_axis'
                    },
                ],
            }
            this.chart_options = {
         
                    scales: {
                        x: {
                            beginAtZero: true,
                        },
                        left_y_axis: { // Define the left y-axis
                            position: 'left',
                            beginAtZero: true,
                            title: {
                                        display: true,
                                        text: `${dataset_name}`
                                    },
                        },
                        right_y_axis: { // Define the right y-axis
                            position: 'right',
                            beginAtZero: true,
                            title: {
                                        display: true,
                                        text: `nr of pairs`
                                    },

                        },
                    }
                }




            this.init_chart('timechart')

        },



    },
    computed:{
        ...mapState([]),
    },
    watch:{

    },
}
</script>
<style scoped lang="scss">
</style>