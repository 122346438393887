<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="12" md="12">
                    <intel-defi-cashflow-graph></intel-defi-cashflow-graph>
                    
                </v-col>
                <v-col cols="12" md="12">
                    
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import IntelDefiCashflowGraph from './intel-defi-cashflow-graph.vue'
export default {
    name: 'intel-state-of-market',
    components:{
        IntelDefiCashflowGraph
    },
    emits:[
    ],
    data(){return {
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
    
    },
    methods:{
        ...mapActions([]),
    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>