<template>
    <div v-if="metrics">

                    <v-autocomplete 
                    v-model="selected_metric" 
                    :items="metrics" 
                        
                    item-title="fnc_name"  
                    item-text="fnc_name" 
                    return-object
                    :label="label" 
                    outlined
                    rounded
                   
                    dense
                    color="white"
                    :persistent-hint="true"
                    :hint="`${metrics.length} Metrics Available`" >
                        <template v-slot:selection="data">
                            {{ data.item.fnc_name.replaceAll('_',' ') }}
                        </template>
                        <template v-slot:item="data">
                            <v-list-item-avatar>
                                <v-icon v-if=" data.item.fnc_name.includes('liquidity')" class="ma-0">mdi-cash-multiple</v-icon>
                                <v-icon v-else-if=" data.item.fnc_name.includes('pool')" class="ma-0">mdi-cash-multiple</v-icon>
                                <v-icon v-else-if=" data.item.fnc_name.includes('price')" class="ma-0">mdi-chart-areaspline</v-icon>
                                <v-icon v-else-if=" data.item.fnc_name.includes('volume')" class="ma-0">mdi-chart-areaspline</v-icon>
                                <v-icon v-else-if=" data.item.fnc_name.includes('market')" class="ma-0">mdi-chart-areaspline</v-icon>
                                <v-icon v-else-if=" data.item.fnc_name.includes('trade')" class="ma-0">mdi-account-multiple</v-icon>
                                <v-icon v-else-if=" data.item.fnc_name.includes('tax')" class="ma-0">mdi-account-cash</v-icon>
                                <v-icon v-else-if=" data.item.fnc_name.includes('gas')" class="ma-0">mdi-gas-station</v-icon>
                                <v-icon v-else-if=" data.item.fnc_name.includes('age')" class="ma-0">mdi-clock</v-icon>
                                <v-icon v-else class="ma-0">mdi-strategy</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title > <div class="metric-title">{{data.item.fnc_name.replaceAll('metric_','').replaceAll('_',' ')}}</div></v-list-item-title>
                                <v-list-item-subtitle v-html="data.item.docs"></v-list-item-subtitle>
                            </v-list-item-content>

                            <!-- <v-list-item-content>{{data.item.fnc_name.replaceAll('metric_','').replaceAll('_',' ')}}</v-list-item-content> -->
                        </template>
                    </v-autocomplete>
                    
                <!-- <v-col sm="12" md="12">
                    {{selected_metric}}
                </v-col> -->
        <!-- {{metrics}} -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'selector-pair-metric',
    components:{
    },
    emits:[
        'selected'
    ],
    data(){return {
        metrics:undefined,
        selected_metric:undefined,
        }
    },
    props: { 
        domain:{type:String,default:'pair'},
        label:{type:String,default:'Select A Pair Metric'},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_get_all_metrics()
    
    },
    methods:{
        ...mapActions(['get_all_metrics']),
        do_get_all_metrics(){
            this.get_all_metrics({qs:{domain:this.domain}}).then((r)=>{
                this.metrics = r.data
            })
        },

    },
    computed:{
        ...mapState([]),
    },
    watch:{
        selected_metric(newV, oldV){
            this.$emit('selected', newV)
        },
        domain(nv,ov){
            this.do_get_all_metrics()
        }
    },
}
</script>
<style scoped lang="scss">
.metric-title{
    font-size:1.25em;
    text-transform: uppercase;
    font-weight: 800;
}
</style>