<template>
    <div class="trader-intel-cont">
        <v-container fluid v-if="data" >
            <v-row>
                <v-col cols="12" md="12">
                    
        <time-interval-selector start-timeframe="3H"  @interval="(e) => interval = e"></time-interval-selector>
                </v-col>
                <v-col v-if="!is_loading" cols="12" md="12">
                    <v-tabs centered color="white" background-color="transparent" >
                            <v-tab>Buys </v-tab>
                            <v-tab>Sells</v-tab>
                            <!-- BUYS  -->
                            <v-tab-item>
                                <div style="display:flex;font-size: 0.8em; color:gray;">
                                    <base-amount :amount="data.totals.buys.amount"></base-amount>  x{{ data.totals.buys.count }} 
                                </div>
                                <v-sparkline :smooth="16" :gradient="['#f72047', '#ffd200', '#1feaea']" :line-width="3" :value="data.histograms.buys_cnt" auto-draw stroke-linecap="round" >
                                </v-sparkline>
                                <table>
                                    <tr>
                                            <th></th>
                                            <th> Trader</th>
                                            <th> Tokens</th>
                                            <th> Amount</th>
                                            <th> Trades</th>
                                            <th> </th>
                
                                    </tr>
                                    <tr v-for="(t,i) in data.buys" :key="t.trader_id">
                                        <td> {{i+1}}. </td>
                                        <td style="white-space: nowrap;"> <trader-link-to-page :traderId="t.trader_id"> </trader-link-to-page> </td>
                     
                                        <td> 
                                            <div v-for="tkn in t.tokens_invested" :key="tkn">
                                                <pair-link-to-page :pair="data.tokens[tkn]"></pair-link-to-page> 
                                            </div>
                                        </td>
                           
                                        <td> <base-amount :amount="t.amount"></base-amount> </td>
                                        <td> {{t.count}} </td>
                                        <td> <v-btn class="ma-0" elevation="2" color="default" x-small    @click="selected_trades = t.trades; trades_dialog=true"> <v-icon class="ma-0">mdi-swap-vertical</v-icon> </v-btn> </td>
                                    </tr>
                                </table>
                                <v-btn class="ma-0"  elevation="2" small  @click="max_disp_n = max_disp_n + 50" >more</v-btn>
                                <trader-id-list-actions :traders="data.buys.map(x=>{return {id:x.trader_id}})"></trader-id-list-actions>

                            </v-tab-item>
                            <!-- SELLS  -->
                            <v-tab-item>
                                <div style="display:flex;font-size: 0.8em; color:gray;">
                                    <base-amount :amount="data.totals.sells.amount"></base-amount>  x{{ data.totals.sells.count }} 
                                </div>

                                <v-sparkline :smooth="16" :gradient="['#f72047', '#ffd200', '#1feaea']" :line-width="3" :value="data.histograms.sells_cnt" auto-draw stroke-linecap="round" >
                                </v-sparkline>
                                <table>
                                    <tr>
                                            <th></th>
                                            <th> Trader</th>
                                            <th> Tokens</th>
                                            <th> Amount</th>
                                            <th> Trades</th>
                                            <th> </th>
                
                                    </tr>
                                    <tr v-for="(t,i) in data.sells" :key="t.trader_id">
                                        <td> {{i+1}}. </td>
                                        <td style="white-space: nowrap;"> <trader-link-to-page :traderId="t.trader_id"> </trader-link-to-page> </td>
                     
                                        <td> 
                                            <div v-for="tkn in t.tokens_invested" :key="tkn">
                                                <pair-link-to-page :pair="data.tokens[tkn]"></pair-link-to-page> 
                                            </div>
                                        </td>
                           
                                        <td> <base-amount :amount="t.amount"></base-amount> </td>
                                        <td> {{t.count}} </td>
                                        <td> <v-btn class="ma-0" elevation="2" color="default" x-small    @click="selected_trades = t.trades; trades_dialog=true"> <v-icon class="ma-0">mdi-swap-vertical</v-icon> </v-btn> </td>
                                    </tr>
                                </table>
                                <v-btn class="ma-0"  elevation="2" small  @click="max_disp_n = max_disp_n + 50" >more</v-btn>
                                <trader-id-list-actions :traders="data.sells.map(x=>{return {id:x.trader_id}})"></trader-id-list-actions>

                            </v-tab-item>
                    </v-tabs>

                </v-col>
                <v-col v-else sm="12" md="12">
                    Loading
                </v-col>
            </v-row>
        </v-container>
        <!-- <pre> {{data}} </pre> -->

        <!-- Trades  -->
        <v-dialog v-model="trades_dialog"  max-width="70vw" >
            <v-card>
                        <v-card-title>
                        <span class="text-h5">Trades</span>
                        </v-card-title>
                        <v-card-text v-if="selected_trades">
                            <!-- {{selected_trades}} -->
                            <table>
                                <tr>
                                    <th>Time</th>
                                    <th>Token</th>
                                    <th>Amount</th>
                                </tr>
                                <tr v-for="t in selected_trades" :key="t.id">
                                    <td> {{t.time_stamp}} </td>
                                    <td> 
                                        <div style="display:flex; justify-content:space-around">
                                            <div class="mt-1"> 
                                                <pair-link-to-page :pair="data.tokens[t.token_id]"></pair-link-to-page>
                                            </div>

                                        </div>
                                    </td>
                                    <td> 
                                        <base-amount :amount="t.amount"></base-amount>
                                    </td>
                                </tr>
                            </table>
                            

                        
                        </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="trades_dialog = false" > Close </v-btn>
                        <v-btn color="blue darken-1" text @click="trades_dialog = false" > Save </v-btn>
                        </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
import BaseAmount from './base-amount.vue'
import TraderIdListActions from './dynamic_lists/trader-id-list-actions.vue'
import ExternalLinkDextools from './external-link-dextools.vue'
import PairLinkToPage from './pair-link-to-page.vue'
import TimeIntervalSelector from './time-interval-selector.vue'
import TraderLinkToPage from './trader-link-to-page.vue'
export default {
    name: 'intel-trades-traders-agg',
    components:{
        TimeIntervalSelector,
        ExternalLinkDextools,
        PairLinkToPage,
        BaseAmount,
        TraderLinkToPage,
        TraderIdListActions,
    },
    emits:[
    ],
    data(){return {
        interval:60*10,
        data:undefined,
        trades_dialog:false, 
        selected_trades:undefined,
        max_disp_n:25,
        interval:60*60*3,
        is_loading: false,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.do_intel_trader_traders_agg()
    },
    methods:{
        ...mapActions(['intel_trader_traders_agg']),
        do_intel_trader_traders_agg(){
            this.is_loading = true
            this.intel_trader_traders_agg({qs:{interval:this.interval, show_max:this.max_disp_n},data:{}}).then(r=>{
                this.data = r.data
                this.is_loading = false
            })
            
        }

    },
    computed:{
        ...mapState([]),
    },
    watch:{
        interval(nv,ov){this.do_intel_trader_traders_agg()},
        max_disp_n(nv,ov){this.do_intel_trader_traders_agg()},

    },
}
</script>
<style scoped lang="scss">
.trader-intel-cont{
    height:calc(100vh - 117px);
    overflow-y:scroll;
}

a{
    color:white!important;
}
td{
    text-align: center;
}
</style>