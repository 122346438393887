<template>
    <div>
        this is test-streaming
        <pre>
            {{output}}
        </pre>
    </div>
</template>
<script>

async function* get_streaming(url) {
    try {
        const response = await fetch(url); // Replace this with your Django view URL
        const reader = response.body.getReader();
        while (true) {
            const { done, value } = await reader.read();
            if (done) { break; }
            const json = JSON.parse(new TextDecoder().decode(value));
            yield json;
        }
    } catch (error) {
        console.error('Error:', error);
    }
}


// Begin Component Imports
// End Component Imports
import { mapState,mapActions } from 'vuex'
export default {
    name: 'test-streaming',
    components:{
    },
    emits:[
    ],
    data(){return {
        output:undefined,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.loadAndDisplayOutput().then(r=>r)
    
    },
    methods:{
        ...mapActions([]),
        async loadAndDisplayOutput() {
            const url = `${this.db_url}test/stream`
            const loader = get_streaming(url);
            while (true) {
                const { value, done } = await loader.next();
                if (done) { break; }
                console.log(value);
                this.output = value
            }
        }
    },
    computed:{
        ...mapState(['db_url']),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
</style>