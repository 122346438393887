<template>
    <div>
        <table>
            <tr>
                <th>Time</th>
                <th>Trader</th>
                <th>direction</th>
                <th>Token</th>
                <th>Amount</th>
                <th>Price</th>
            </tr>
            <tr v-for="trade in stack" :key="trade.id">
                <!-- <td>{{trade.id}}</td> -->
                <td>
                    <!-- {{trade.trade_time}} -->
                    <time-display :time="trade.trade_time"></time-display>
                </td>
                <td> 
                    <trader-link-to-page :traderId="trade.trader"></trader-link-to-page>
                </td>
                <td class="trade_direction" :style="style_decide_css_for_direction(trade.direction)">
                    {{trade.direction}}
                </td>
                <td> 
                    <pair-link-to-page :pair="trade.token"></pair-link-to-page>
                    <!-- {{trade.token_in.symbol}} -> {{trade.token_out.symbol}}  -->
                </td>
                <td> 
                    
                    <!-- {{trade.amount}} -->
                    <base-amount :amount="trade.amount"></base-amount>
                </td>
                <td>
                    <!-- {{trade.standard_price}} -->
                    <base-amount :amount="trade.standard_price"></base-amount>
                </td>

            </tr>
        </table>
        <!-- <pre> {{new_data}} </pre> -->
    </div>
</template>
<script>
// Begin Component Imports
// End Component Imports

async function* streaming_get(url) {
    try {
        const response = await fetch(url); 
        const reader = response.body.getReader();
        while (true) {
                try{

                    const { done, value } = await reader.read();
                if (done) { break; }
                const json = JSON.parse(new TextDecoder().decode(value));
                yield json;
                }catch{
                    
                }
        }
    } 
    catch (error) { console.error('Error:', error); }
}




import { mapState,mapActions } from 'vuex'
import BaseAmount from './base-amount.vue';
import PairLinkToPage from './pair-link-to-page.vue';
import TimeDisplay from './time-display.vue';
import TraderLinkToPage from './trader-link-to-page.vue';

export default {
    name: 'trades-live-streamer',
    components:{
        TraderLinkToPage,
        BaseAmount,
        TimeDisplay,
        PairLinkToPage

    },
    emits:[
 
    ],
    data(){return {
        new_data: {},
        stack : [],
        max_stack_size : 50,
        }
    },
    props: { 
        //propName:{type:String,default:''},
        //data:{type:Object,default:Object},
        //list:{type:Array,default:Array},
    },
    mounted(){
        this.loadAndDisplayOutput()
    },
    methods:{
        ...mapActions([]),
    stack_data(new_data){
         
        this.stack.unshift(new_data);
        if (this.stack.length > this.max_stack_size) {
            this.stack.pop();
        }
    },
    async loadAndDisplayOutput() {
            const loader = streaming_get('http://134.209.91.206/stream/trades');
            while (true) {
                const { value, done } = await loader.next();
                if (done) { break; }
                this.stack_data(value);
                this.new_data = value
            }
        },
    style_decide_css_for_direction(direction){
        if (direction == 'buy'){ return 'background-color:#69ff8985; color:white;' }
        if (direction == 'sell'){ return 'background-color:#ff696985; color:white;' }
        if (direction == 'swap'){ return 'background-color:#ffb06985; color:white;' }

    },


    },
    computed:{
        ...mapState([]),
    },
    watch:{
    },
}
</script>
<style scoped lang="scss">
.trade_direction{
    text-transform:uppercase;
    text-align: center;
}

td{
    padding:3px;
}
</style>